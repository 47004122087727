import { z } from "zod";
import { planSettings } from "..";
import { profileSlugSchema } from "../profile/profile";

export const schema = z.object({
  id: z.string().uuid(),
  email: z.string(),
  username: profileSlugSchema,
  avatarId: z.string().uuid().nullable(),
  auth0UserId: z.string().nullable(),
  backgroundImageId: z.string().uuid().nullable(),
  createdAt: z.coerce.date().default(new Date()),
  updatedAt: z.coerce.date().default(new Date()),
  avatarUrl: z.string().optional(),
  backgroundImageUrl: z.string().optional(),
  ethAddress: z.string().nullable().optional(),
  rank: z.number().nullable(),
  //allow empty string to clear handle
  twitterHandle: z
    .string()
    .regex(/^([a-zA-Z0-9_]{3,15})?$/)
    .nullable(),
  telegramHandle: z
    .string()
    .regex(/^([a-zA-Z0-9_]{5,32})?$/)
    .nullable(),
  discordHandle: z.string().nullable(),
  stripeId: z.string().optional().nullable(),
  lagoSubscriptionId: z.string().optional().nullable(),
  quicknodeId: z.string().optional().nullable(),
  profileId: z.string().uuid(),
  domainType: z.literal("User").default("User"),
  treasuryAppId: z.string().uuid().optional().nullable(),
  isBanned: z.boolean().default(false),
});

const topAnalystSchema = schema.extend({
  dashboardLikes: z.number(), // Assuming dashboardLikes is a number for simplicity
});

export const topAnalystsSchema = z.array(topAnalystSchema);

export const displaySchema = schema
  .pick({
    id: true,
    username: true,
    avatarUrl: true,
    backgroundImageUrl: true,
    profileId: true,
  })
  .partial();

export const updateSchema = schema
  .pick({
    username: true,
    twitterHandle: true,
    discordHandle: true,
    telegramHandle: true,
    avatarId: true,
    backgroundImageId: true,
    stripeId: true,
    lagoSubscriptionId: true,
    quicknodeId: true,
    email: true,
    auth0UserId: true,
    treasuryAppId: true,
  })
  .partial();

export const createSchema = schema.pick({
  username: true,
  email: true,
  quicknodeId: true,
  ethAddress: true,
  discordHandle: true,
  auth0UserId: true,
});

export const usernameSchema = schema
  .pick({
    username: true,
  })
  .partial();

export type User = z.infer<typeof schema>;
export type UserUpdate = z.infer<typeof updateSchema>;
export type UserCreate = z.infer<typeof createSchema>;
export type UserDisplay = z.infer<typeof displaySchema>;
export type TopAnalyst = z.infer<typeof topAnalystSchema>;
export type CurrentUser = User & {
  accessToken: string;
  currentProfileId: string;
  planSettings: planSettings.PlanSettings;
};
export type FeatureFlag =
  | "internal-product"
  | "sf-early-access"
  | "viz-2"
  | "studio-redesign"
  | "dashboard-embed"
  | "user-score-surfacing-v1"
  | `quests-ab-${string}`
  | `dashboard-v3`
  | "dashboard-viewers"
  | "dashboard-tags"
  | "rebrand"
  | "earn-maintenance";

export interface UserSearchParams {
  search?: string;
  sort: "alpha" | "rank";
  offset: number;
  pageSize: number;
}

export interface UserSearchResponse {
  users: User[];
  totalCount: number;
}
