import { sortBy } from "lodash-es";
import { z } from "zod";
import { query } from "..";
import { newQueryParamsSchema } from "./query-param";

export const queryStatusSchema = z.enum(["canceled", "failed", "queued", "running", "finished"]);
export const executionTypeSchema = z.enum([
  "REALTIME",
  "REFRESH",
  "DASHBOARD",
  "SELECT_AND_RUN",
  "DASHBOARD_REFRESH",
  "DASHBOARD_PARAMETERS",
]);
export const userTierSchema = z.enum(["community", "builder"]);
const jsonRowsSchema = z.array(z.record(z.unknown()));
const csvRowsSchema = z.array(z.array(z.unknown()));

export const queryRunSnowflakeTagSchema = z.object({
  apiKey: z.string().nullable().optional(),
  dashboardId: z.string().nullable().optional(),
  parameterized: z.boolean().nullable().optional(),
  queryId: z.string().nullable().optional(),
  type: executionTypeSchema.nullable().optional(),
  sdkPackage: z.string().nullable().optional(),
  sdkVersion: z.string().nullable().optional(),
  ttlMinutes: z.number().nullable().optional(),
  userId: z.string().nullable().optional(),
  executedAsUserId: z.string().uuid().nullable().optional(),
  username: z.string().nullable().optional(),
  userTier: userTierSchema.nullable().optional(),
  livequeryProvider: z.string().nullable().optional(),
});

export const queryRunSchema = z.object({
  id: z.string(),
  status: queryStatusSchema,
  queryId: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  snowflakeRole: z.string(),
  executionType: executionTypeSchema,
  columnLabels: z.array(z.string()).nullable(),
  columnTypes: z.array(z.string()).nullable(),
  message: z.string().nullable(),
  rowCount: z.number().nullable(),
  startedAt: z.coerce.date().nullable(),
  endedAt: z.coerce.date().nullable(),
  createdById: z.string().uuid().nullable(),
  snowflakeQueryId: z.string().nullable(),
  statement: z.string().nullable(),
  updatedById: z.string().uuid().nullable(),
  snowflakeSessionId: z.string().nullable(),
  s3Results: z.boolean().nullable(),
  snowflakeTag: queryRunSnowflakeTagSchema.nullable(),
});

const queryRunParamsSchema = z.array(query.queryParamSchema.pick({ name: true, value: true, type: true }));

export const queryRunExecuteRequestSchema = z.object({
  statement: z.string().min(10),
  executionType: executionTypeSchema,
  dashboardId: z.string().uuid().optional(),
  parameters: queryRunParamsSchema.optional(),
});

export const newQueryRunSchema = z.object({
  statement: z.string(),
  userId: z.string().uuid(),
  executionType: executionTypeSchema,
  queryId: z.string().uuid().optional(),
  queryParams: newQueryParamsSchema.optional(),
  snowflakeTag: queryRunSnowflakeTagSchema.nullable().optional(),
});

export const updateQueryRunSchema = z
  .object({
    status: queryStatusSchema,
    endedAt: z.date(),
    message: z.string(),
    snowflakeQueryId: z.string(),
    snowflakeSessionId: z.string(),
    columnLabels: z.array(z.string()),
    columnTypes: z.array(z.string()),
    rowCount: z.number(),
    snowflakeRole: z.string(),
  })
  .partial();

export function isEphemeral(type: ExecutionType) {
  return type === "SELECT_AND_RUN" || type === "DASHBOARD_PARAMETERS" || type === "DASHBOARD";
}

export type QueryRunStatus = z.infer<typeof queryStatusSchema>;
export type ExecutionType = z.infer<typeof executionTypeSchema>;
export type QueryRun = z.infer<typeof queryRunSchema>;
export type QueryRunNew = z.infer<typeof newQueryRunSchema>;
export type QueryRunParams = z.infer<typeof queryRunParamsSchema>;
export type QueryRunUpdate = z.infer<typeof updateQueryRunSchema>;
export type QueryRunSnowflakeTag = z.infer<typeof queryRunSnowflakeTagSchema>;
export type JsonRowsSchema = z.infer<typeof jsonRowsSchema>;
export type CsvRowsSchema = z.infer<typeof csvRowsSchema>;

export type QueryRunResult = {
  columns: string[];
  types: string[];
  csvData: CsvRowsSchema;
  jsonData: JsonRowsSchema;
  rowCount: number;
  errorMessage: string | undefined;
  status: QueryRunStatus;
  startedAt: Date | string | undefined | null;
  endedAt: Date | undefined | string | null;
  queryId: string | undefined;
  queryRunId: string | undefined;
  executionType: ExecutionType | undefined;
  resultsRetrieved: boolean;
};

export const createEmptyQueryRunResult = (queryId: string): QueryRunResult => ({
  columns: [],
  types: [],
  csvData: [],
  jsonData: [],
  rowCount: 0,
  errorMessage: "",
  status: "queued",
  startedAt: undefined,
  endedAt: undefined,
  queryId,
  queryRunId: undefined,
  executionType: undefined,
  resultsRetrieved: false,
});

export const createLoadingQueryRunResult = (queryId: string): QueryRunResult => ({
  columns: [],
  types: [],
  csvData: [],
  jsonData: [],
  rowCount: 0,
  errorMessage: "",
  status: "finished",
  startedAt: undefined,
  endedAt: undefined,
  queryId,
  queryRunId: undefined,
  executionType: undefined,
  resultsRetrieved: false,
});

export const createErrorQueryRunResult = (queryId: string, error: Error): QueryRunResult => ({
  columns: [],
  types: [],
  csvData: [],
  jsonData: [],
  rowCount: 0,
  errorMessage: error.message,
  status: "failed",
  startedAt: undefined,
  endedAt: undefined,
  queryId,
  queryRunId: undefined,
  executionType: undefined,
  resultsRetrieved: false,
});

// export const queryRunResultSchema = z.object({
//   columns: z.array(z.string()),
//   types: z.array(z.string()),
//   csvData: csvRowsSchema,
//   jsonData: jsonRowsSchema,
//   rowCount: z.number(),
//   errorMessage: z.string().optional(),
//   status: queryStatusSchema,
//   startedAt: z.union([z.date(), z.string(), z.null(), z.undefined()]),
//   endedAt: z.union([z.date(), z.string(), z.null(), z.undefined()]),
//   queryId: z.string().optional(),
//   queryRunId: z.string().optional(),
//   executionType: z.optional(executionTypeSchema),
//   resultsRetrieved: z.boolean(),
// });

// export type QueryRunResult = z.infer<typeof queryRunResultSchema>;

export const isRunning = (status: QueryRunStatus) => status === "running" || status === "queued";
export const isFinished = (status: QueryRunStatus) => status === "finished";
export const isFailed = (status: QueryRunStatus) => status === "failed";
export const isCanceled = (status: QueryRunStatus) => status === "canceled";

export const getParamsHash = (params: QueryRunParams, identifier?: string) => {
  const joined = sortBy(params, "name")
    .map((param) => `${param.name}:${param.value}`)
    .join(",");
  if (identifier) {
    return btoa(`${joined}-${identifier}`);
  }
  return btoa(joined);
};
