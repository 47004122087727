import { z } from "zod";

const groupingSchema = z.object({ key: z.string() });

// These types come from snowflake
export const dataTypeSchema = z.enum([
  "text",
  "fixed",
  "timestamp_ntz",
  "real",
  "timestamp_ltz",
  "object",
  "date",
  "timestamp_tz",
]);
export type DataType = z.infer<typeof dataTypeSchema>;

// Options schema is what we pass down to highcharts
const stackingSchema = z.enum(["normal", "percent"]);
const plotOptionsSchema = z
  .object({
    area: z.optional(z.object({ stacking: stackingSchema.optional() }).passthrough()),
    bar: z.optional(z.object({ stacking: stackingSchema.optional() }).passthrough()),
    column: z.optional(z.object({ stacking: stackingSchema.optional() }).passthrough()),
    histogram: z.optional(
      z.object({
        binsNumber: z.optional(z.number()),
        binsWidth: z.optional(z.number()),
      }),
    ),
  })
  .passthrough();

export const yAxisOptions = z
  .object({
    title: z.optional(z.object({ text: z.optional(z.string()) }).passthrough()),
    type: z.optional(z.literal("logarithmic")),
    opposite: z.optional(z.boolean()),
    reversed: z.optional(z.boolean()),
  })
  .passthrough();

export const optionsSchema = z.object({
  title: z.optional(
    z
      .object({
        text: z.optional(z.string().min(1)),
      })
      .passthrough(),
  ),
  subtitle: z.optional(
    z
      .object({
        text: z.optional(z.string()),
      })
      .passthrough(),
  ),
  xAxis: z.optional(
    z
      .object({
        title: z.optional(
          z
            .object({
              text: z.optional(z.string()),
            })
            .passthrough(),
        ),
        reversed: z.optional(z.boolean()),
      })
      .passthrough(),
  ),
  yAxis: z.optional(z.union([yAxisOptions, z.array(yAxisOptions)])),
  legend: z.optional(
    z
      .object({
        align: z.optional(z.union([z.literal("left"), z.literal("right"), z.literal("center")])),
        verticalAlign: z.optional(z.union([z.literal("bottom"), z.literal("top"), z.literal("middle")])),
        enabled: z.optional(z.boolean()),
        reversed: z.optional(z.boolean()),
      })
      .passthrough(),
  ),
  bigNumber: z
    .object({
      primaryValue: z.optional(z.number()),
      secondaryValue: z.optional(z.number()),
      rows: z.optional(z.number()),
      prefix: z.string().optional(),
      suffix: z.string().optional(),
      decimals: z.number().min(0).optional(),
      d3Format: z.string().optional(),
      autoFormat: z.boolean().optional().default(true),
      caption: z.string().optional(),
    })
    .optional(),
  table: z.optional(
    z
      .object({
        resultsRetrieved: z.boolean().default(false),
        columns: z.array(z.string()),
        types: z.array(z.string()),
        csvData: z.array(z.array(z.unknown())),
      })
      .passthrough(),
  ),
  colorAxis: z.optional(
    z
      .object({
        minColor: z.optional(z.string()),
        maxColor: z.optional(z.string()),
        reversed: z.optional(z.boolean()),
      })
      .passthrough(),
  ),
  plotOptions: z.optional(plotOptionsSchema),
});

export type Options = z.infer<typeof optionsSchema>;
export type PlotOptions = z.infer<typeof plotOptionsSchema>;

const colorsSchema = z.record(z.string(), z.string());

const baseConfigSchema = z.object({
  colors: z.optional(colorsSchema),
});

const xSchema = z.object({
  key: z.string(),
  type: z.string().default("text"),
  name: z.optional(z.string()),
  date: z.optional(
    z.object({
      step: z.optional(z.number()),
      unit: z.optional(z.enum(["day", "month", "year"])),
    }),
  ),
});

export type X = z.infer<typeof xSchema>;

const ySchema = z.object({
  key: z.string(),
  type: z.string().default("real"),
  name: z.optional(z.string()),
  aggregation: z.optional(z.enum(["sum", "avg", "count"])),
  position: z.optional(z.union([z.literal("left"), z.literal("right")])),
  logScale: z.optional(z.boolean()),
});

export type Y = z.infer<typeof ySchema>;

const xyBaseSchema = baseConfigSchema.extend({
  x: z.optional(xSchema),
  y: z.optional(z.array(ySchema)),
  grouping: z.optional(groupingSchema),
});

// BAR TYPES

export const barInputsSchema = z.object({
  type: z.literal("bar"),
  config: xyBaseSchema,
});
export type BarInputs = z.infer<typeof barInputsSchema>;

export const barStackedInputsSchema = z.object({
  type: z.literal("bar-stacked"),
  config: xyBaseSchema,
});
export type BarStackedInputs = z.infer<typeof barStackedInputsSchema>;

export const barNormalizedInputsSchema = z.object({
  type: z.literal("bar-normalized"),
  config: xyBaseSchema,
});
export type BarNormalizedInputs = z.infer<typeof barNormalizedInputsSchema>;

export const barHorizontalInputsSchema = z.object({
  type: z.literal("bar-horizontal"),
  config: xyBaseSchema,
});
export type BarHorizontalInputs = z.infer<typeof barHorizontalInputsSchema>;

export const barHorizontalStackedInputsSchema = z.object({
  type: z.literal("bar-horizontal-stacked"),
  config: xyBaseSchema,
});
export type BarHorizontalStackedInputs = z.infer<typeof barHorizontalStackedInputsSchema>;

export const barHorizontalNormalizedInputsSchema = z.object({
  type: z.literal("bar-horizontal-normalized"),
  config: xyBaseSchema,
});
export type BarHorizontalNormalizedInputs = z.infer<typeof barHorizontalNormalizedInputsSchema>;

export const barLineInputsSchema = z.object({
  type: z.literal("bar-line"),
  config: xyBaseSchema.extend({
    line: z.optional(
      ySchema.extend({
        ignoreGrouping: z.optional(z.boolean().default(false)),
      }),
    ),
  }),
});

export type BarLineInputs = z.infer<typeof barLineInputsSchema>;

// Candlestick

export const candlestickInputsSchema = z.object({
  type: z.literal("candlestick"),
  config: xyBaseSchema.extend({
    constructorType: z.optional(z.string().default("stockChart")),
    colors: z.optional(
      z.intersection(
        z.record(z.string()),
        z.object({
          upwardTrend: z.string(),
          downwardTrend: z.string(),
        }),
      ),
    ),
    open: z.optional(
      z.object({
        key: z.string(),
        type: z.string().default("text"),
      }),
    ),
    high: z.optional(
      z.object({
        key: z.string(),
        type: z.string().default("text"),
      }),
    ),
    low: z.optional(
      z.object({
        key: z.string(),
        type: z.string().default("text"),
      }),
    ),
    close: z.optional(
      z.object({
        key: z.string(),
        type: z.string().default("text"),
      }),
    ),
  }),
});

export type CandlestickInputs = z.infer<typeof candlestickInputsSchema>;

// Line

export const lineInputsSchema = z.object({
  type: z.literal("line"),
  config: xyBaseSchema,
});
export type LineInputs = z.infer<typeof lineInputsSchema>;

// Area

export const areaInputsSchema = z.object({
  type: z.literal("area"),
  config: xyBaseSchema,
});
export type AreaInputs = z.infer<typeof areaInputsSchema>;

export const areaStackedInputsSchema = areaInputsSchema.extend({
  type: z.literal("area-stacked"),
});
export type AreaStackedInputs = z.infer<typeof areaStackedInputsSchema>;

const areaNormalizedInputs = areaInputsSchema.extend({
  type: z.literal("area-normalized"),
});
export type AreaNormalizedInputs = z.infer<typeof areaNormalizedInputs>;

// Scatter

export const scatterInputsSchema = z.object({
  type: z.literal("scatter"),
  config: xyBaseSchema.extend({
    z: z.optional(
      z.object({
        key: z.string(),
        type: z.string().default("real"),
        name: z.optional(z.string()),
      }),
    ),
  }),
});
export type ScatterInputs = z.infer<typeof scatterInputsSchema>;

// Histogram
export const histogramInputsSchema = baseConfigSchema.extend({
  type: z.literal("histogram"),
  config: xyBaseSchema,
});

export type HistogramInputs = z.infer<typeof histogramInputsSchema>;

// Heatmap
export const heatmapInputsSchema = baseConfigSchema.extend({
  type: z.literal("heatmap"),
  config: xyBaseSchema.extend({
    value: z.optional(
      z.object({
        key: z.string(),
        type: z.string().default("real"),
        name: z.optional(z.string()),
      }),
    ),
  }),
});

export type HeatmapInputs = z.infer<typeof heatmapInputsSchema>;

// Pie

export const pieInputsSchema = baseConfigSchema.extend({
  type: z.literal("pie"),
  config: z.object({
    colors: z.optional(colorsSchema),
    slice: z.optional(
      z.object({
        key: z.string(),
        type: z.string().default("text"),
        name: z.optional(z.string()),
      }),
    ),
    value: z.optional(
      z.object({
        type: z.string().default("real"),
        key: z.string(),
        name: z.optional(z.string()),
      }),
    ),
  }),
});

export type PieInputs = z.infer<typeof pieInputsSchema>;

// Table

export const tableInputsSchema = z.object({
  type: z.literal("viz-table"),
  config: baseConfigSchema.extend({
    columnOptions: z.record(
      z.object({
        visible: z.boolean().optional(),
        customLabel: z.optional(z.string()),
        position: z.number().default(0),
      }),
    ),
  }),
});

export type TableInputs = z.infer<typeof tableInputsSchema>;
export type TableColumnOptions = Record<string, TableInputs["config"]["columnOptions"][string]>;

// Big Number

export const bigNumberInputsSchema = z.object({
  type: z.literal("big-number"),
  config: baseConfigSchema.extend({
    caption: z.string().optional(),
    valueKey: z.string().optional(), // Key in result object to pull value from
    rowNumber: z.number().min(1).optional(), // Row number to pull value from
    prefix: z.string().optional(),
    suffix: z.string().optional(),
    decimals: z.number().min(0).optional(),
    d3Format: z.string().optional(),
    autoFormat: z.boolean().optional().default(true),
    secondaryValueKey: z.string().optional(),
    secondaryRowNumber: z.number().min(1).optional(),
  }),
});

export const bigNumberConfigSchema = baseConfigSchema.extend({
  caption: z.string().optional(),
  valueKey: z.string().optional(), // Key in result object to pull value from
  rowNumber: z.number().min(1).optional(), // Row number to pull value from
  prefix: z.string().optional(),
  suffix: z.string().optional(),
  decimals: z.number().min(0).optional(),
  d3Format: z.string().optional(),
  autoFormat: z.boolean().optional().default(true),
  secondaryValueKey: z.string().optional(),
  secondaryRowNumber: z.number().min(1).optional(),
});

export type BigNumberInputs = z.infer<typeof bigNumberInputsSchema>;

const xyInputsSchema = z.discriminatedUnion("type", [
  barInputsSchema,
  barStackedInputsSchema,
  barNormalizedInputsSchema,
  barHorizontalInputsSchema,
  barHorizontalStackedInputsSchema,
  barHorizontalNormalizedInputsSchema,
  barLineInputsSchema,
  candlestickInputsSchema,
  lineInputsSchema,
  areaInputsSchema,
  areaStackedInputsSchema,
  areaNormalizedInputs,
  scatterInputsSchema,
  histogramInputsSchema,
  heatmapInputsSchema,
]);

const nonXyInputsSchema = z.discriminatedUnion("type", [pieInputsSchema, tableInputsSchema, bigNumberInputsSchema]);

const inputsSchema = z.union([xyInputsSchema, nonXyInputsSchema]);

export type Inputs = z.infer<typeof inputsSchema>;
export type XYInputs = z.infer<typeof xyInputsSchema>;

export const visualizationTypeSchema = inputsSchema.transform((v) => v.type);
export type VisualizationType = z.infer<typeof visualizationTypeSchema>;

export const schema = z.object({
  id: z.string(),
  version: z.enum(["1", "2", "3"]).default("3"),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  config: z.object({
    inputs: inputsSchema,
    options: optionsSchema.passthrough(),
  }),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  queryId: z.string().uuid(),
  profileId: z.string().uuid(),
  forkedFromId: z.string().uuid().nullable(),
  domainType: z.literal("Visualization").default("Visualization"),
});

export type Visualization = z.infer<typeof schema>;

export const newSchema = schema.pick({
  version: true,
  config: true,
  queryId: true,
  forkedFromId: true,
});
export type VisualizationNew = z.infer<typeof newSchema>;

const xyTypes: VisualizationType[] = [
  "bar",
  "bar-stacked",
  "bar-normalized",
  "bar-horizontal",
  "bar-horizontal-normalized",
  "bar-horizontal-stacked",
  "line",
  "area",
  "area-stacked",
  "area-normalized",
  "scatter",
  "bar-line",
  "histogram",
  "candlestick",
  "heatmap",
];

export const isXYInput = (i: Inputs): i is XYInputs => xyTypes.includes(i.type);
export const isPieInput = (i: Inputs): i is PieInputs => i.type === "pie";
export const isBarLineInput = (i: Inputs): i is BarLineInputs => i.type === "bar-line";
export const isTableInput = (i: Inputs): i is TableInputs => i.type === "viz-table";
export const isBigNumberInput = (i: Inputs): i is BigNumberInputs => i.type === "big-number";
export const isHistogramInput = (i: Inputs): i is HistogramInputs => i.type === "histogram";
export const isHeatmapInput = (i: Inputs): i is HeatmapInputs => i.type === "heatmap";
export const isScatterInput = (i: Inputs): i is ScatterInputs => i.type === "scatter";
export const isCandlestick = (i: Inputs): i is CandlestickInputs => i.type === "candlestick";

export type BigNumberConfig = z.infer<typeof bigNumberInputsSchema>["config"];
