import { z } from "zod";

export const schema = z.object({
  id: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  createdById: z.string(),
  updatedById: z.string().optional().nullable(),
  PartnerProjects: z.array(z.string().uuid()).optional(),
  name: z.string(),
  goal: z.string(),
  goalAmount: z.number().optional().nullable(),
  start: z.coerce.date(),
  end: z.coerce.date(),
});

export const campaignNewSchema = schema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  createdById: true,
  updatedById: true,
});
export const campaignUpdateSchema = schema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  createdById: true,
  updatedById: true,
  PartnerProjects: true,
});

export type Campaign = z.infer<typeof schema>;
export type CampaignNew = z.infer<typeof campaignNewSchema>;
export type CampaignUpdate = z.infer<typeof campaignUpdateSchema>;
