import { z } from "zod";
import { PlanCode, planCodeSchema } from "../plan";

export const subscriptionStateSchema = z.enum(["ACTIVE", "EXPIRED", "CANCELLED", "PENDING"]);
export const TRIAL_LENGTH_DAYS = 14;

export const subscriptionSchema = z.object({
  id: z.string().uuid(),
  customerId: z.string().uuid(),
  planCode: planCodeSchema,
  startedAt: z.coerce.date().optional(),
  downgradeDate: z.coerce.date().optional(),
});

export const customerSchema = z.object({
  id: z.string().uuid(),
  name: z.string().optional().nullable(),
});

export const subscriptionPaymentMethodSchema = z.object({
  type: z.enum(["STRIPE"]),
  id: z.string(),
});

export const chargeSchema = z.object({
  units: z.coerce.number(),
  amountCents: z.number(),
  eventsCount: z.number(),
  name: z.string(),
  code: z.string(),
  aggregationType: z.enum(["sum_agg"]),
  chargeModel: z.enum(["graduated"]),
});

export const usageSchema = z.object({
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amountCents: z.number(),
  charges: z.array(chargeSchema),
});

export const customerCreateSchema = z.object({
  name: z.string(),
  paymentMethod: subscriptionPaymentMethodSchema.optional(),
});

export const customerSubscription = z.object({
  currentSubscription: subscriptionSchema.optional(),
  pendingPlanCode: planCodeSchema.optional(),
});

export type Subscription = z.TypeOf<typeof subscriptionSchema>;
export type Customer = z.TypeOf<typeof customerSchema>;
export type SubscriptionPaymentMethod = z.TypeOf<typeof subscriptionPaymentMethodSchema>;
export type CustomerCreate = z.TypeOf<typeof customerCreateSchema>;
export type Usage = z.TypeOf<typeof usageSchema>;
export type CustomerSubscription = z.TypeOf<typeof customerSubscription>;
export type SubscriptionState = `${PlanCode}_${z.infer<typeof subscriptionStateSchema>}`;
