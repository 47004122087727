import { z } from "zod";
import { schema as userSchema } from "../user/user";

export const memberRoleSchema = z.enum(["owner", "admin", "contributor"]);
export const memberRoleSchemaInvitable = z.enum(["admin", "contributor"]);

export const schema = z.object({
  id: z.string().uuid(),
  role: memberRoleSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  teamId: z.string().uuid(),
  userId: z.string().uuid(),
  domainType: z.literal("Member").default("Member"),
  user: userSchema,
});

export const updateSchema = z.object({
  role: memberRoleSchemaInvitable,
});

export const createSchema = schema
  .pick({
    role: true,
    userId: true,
    teamId: true,
  })
  .extend({
    role: memberRoleSchemaInvitable,
  });

export const createOwnerSchema = schema.pick({
  role: true,
  userId: true,
  teamId: true,
});

export const getRoleRank = (role: string) => {
  if (role === "owner") return 100;
  if (role === "admin") return 50;
  return 0;
};

export type Member = z.infer<typeof schema>;
export type MemberUpdate = z.infer<typeof updateSchema>;
export type MemberCreate = z.infer<typeof createSchema>;
export type MemberCreateOwner = z.infer<typeof createOwnerSchema>;
export type MemberRole = z.infer<typeof memberRoleSchema>;
export type MemberRoleInvitable = z.infer<typeof memberRoleSchemaInvitable>;
