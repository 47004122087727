import { z } from "zod";
import { Identity } from "../utils/identity";
import { ZodSchema } from "../utils/zod";
import { PartnerChainWithAssociations } from "./partner-chain";
import { PartnerTierWithDetails } from "../budget/tier";

export type Partner = {
  id: string;
  slug: string;
  displayName: string;
  description: string | null;
  createdAt: Date;
  updatedAt: Date;
  updatedById: string | null;
  createdById: string | null;
  ecoLeadId: string | null;
  tier?: PartnerTierWithDetails | null;
  fundedByPartnerId?: string;
};

export type PartnerWithChains = Identity<
  Partner & {
    partnerChains: PartnerChainWithAssociations[];
  }
>;

export type PartnerNew = Pick<Partner, "displayName" | "slug"> & {
  ecoLeadId?: string | undefined | null;
  tierId: string;
};

export const partnerSchema = z.object({
  id: z.string().uuid(),
  displayName: z.string(),
  slug: z.string(),
  tierId: z.string().uuid(),
  ecoLeadId: z.string().uuid().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
}) satisfies ZodSchema<PartnerNew>;

export const partnerCreateSchema = z.object({
  displayName: z.string(),
  slug: z.string(),
  tierId: z.string().uuid(),
  ecoLeadId: z.string().uuid().nullable().optional(),
}) satisfies ZodSchema<PartnerNew>;

export type PartnerUpdate = Pick<Partner, "id" | "displayName" | "slug" | "tier"> & {
  ecoLeadId?: string | null;
};

export const partnerUpdateSchema = z.object({
  id: z.string().uuid(),
  displayName: z.string(),
  slug: z.string().min(1),
  tierId: z.string().uuid(),
  ecoLeadId: z.string().uuid().nullable().optional(),
}) satisfies ZodSchema<PartnerUpdate>;
