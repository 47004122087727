import { z } from "zod";
import { schema as secretSchema } from "../secret/secret";

export const secretTypeSchema = z.union([z.literal("user"), z.literal("system")]);

export const schema = z.object({
  id: z.string().uuid(),
  name: z
    .string()
    .min(1)
    .regex(/^[A-Za-z0-9_/-]+$/, "Key can only contain letters, numbers, underscores, and hyphens"),
  type: secretTypeSchema.default("user"),
  createdAt: z.date().default(new Date()),
  updatedAt: z.date().default(new Date()),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  secrets: z.array(secretSchema, {}).default([]),
  domainType: z.literal("SecretContainer").default("SecretContainer"),
});

export const newSchema = schema
  .pick({
    name: true,
  })
  .extend({
    type: schema.shape.type.optional(),
  });

export const updateSchema = schema.pick({
  name: true,
});

export type SecretContainer = z.infer<typeof schema>;
export type SecretContainerNew = z.infer<typeof newSchema>;
export type SecretContainerUpdate = z.infer<typeof updateSchema>;
export type SecretContainerType = z.infer<typeof secretTypeSchema>;
