import { z } from "zod";

export const PlanProvisionWorkflowError = {
  LAGO_SUBSCRIPTION_CREATION_FAILED: "LAGO_SUBSCRIPTION_CREATION_FAILED",
  TRIAL_RECORD_CREATION_FAILED: "TRIAL_RECORD_CREATION_FAILED",
  COMPASS_PROFILE_UPDATE_FAILED: "COMPASS_PROFILE_UPDATE_FAILED",
  COMPASS_PROVISION_DATA_SOURCE_FAILED: "COMPASS_PROVISION_DATA_SOURCE_FAILED",
  INVALID_PLAN_CODE: "INVALID_PLAN_CODE",
  UNKNOWN_PROFILE: "UNKNOWN_PROFILE",
  UNKNOWN_IUSER: "UNKNOWN_USER",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  INVALID_INPUT: "INVALID_INPUT",
  DOWNGRADE_TO_FREE_FAILED: "DOWNGRADE_TO_FREE_FAILED",
} as const;

export const planProvisionWorkflowErrorEnum = z.nativeEnum(PlanProvisionWorkflowError).catch("UNKNOWN_ERROR");
export type PlanProvisionWorkflowErrorType = z.infer<typeof planProvisionWorkflowErrorEnum>;
