import { z } from "zod";

export const schema = z.object({
  id: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  startedAt: z.date().nullable(),
  endedAt: z.date().nullable(),
  queryId: z.string().uuid(),
  status: z.string().max(50),
  compassId: z.string().max(100).nullable(),
  rowCount: z.number().default(0),
  errorMessage: z.string().optional().nullable(),
  statementHash: z.string().nullable(),
});

export const createSchema = schema
  .pick({
    compassId: true,
    queryId: true,
  })
  .extend({
    statement: z.string(),
  });

export const updateSchema = schema
  .pick({
    startedAt: true,
    endedAt: true,
    status: true,
    compassId: true,
    rowCount: true,
    errorMessage: true,
  })
  .partial();

export type QueryRefresh = z.TypeOf<typeof schema>;
export type QueryRefreshUpdate = z.TypeOf<typeof updateSchema>;
export type QueryRefreshCreate = z.TypeOf<typeof createSchema>;
