import { z } from "zod";

export const permission = z.enum([
  "ADHOC_PAYMENT_APPROVE",
  "ADHOC_PAYMENT_SUBMIT",
  "QUEST_REWARDS_EDIT",
  "USER_ADMINISTER",
]);

export const roleChangeResultSchema = z.object({
  keptRoles: z.set(z.string()),
  addedRoles: z.set(z.string()),
  removedRoles: z.set(z.string()),
  keptPermissions: z.set(permission),
  addedPermissions: z.set(permission),
  removedPermissions: z.set(permission),
});

export type Permission = z.infer<typeof permission>;
export type RoleChangeResult = z.infer<typeof roleChangeResultSchema>;
