import { z } from "zod";

export const schema = z.object({
  id: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  createdById: z.string(),
  updatedById: z.string().optional().nullable(),
  name: z.string(),
  type: z.string(),
  metadata: z.record(z.string()).optional().nullable(),
});

export const projectNewSchema = schema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  updatedById: true,
  createdById: true,
});
export const projectUpdateSchema = schema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  createdById: true,
  updatedById: true,
});

export type PartnerProject = z.infer<typeof schema>;
export type PartnerProjectNew = z.infer<typeof projectNewSchema>;
export type PartnerProjectUpdate = z.infer<typeof projectUpdateSchema>;
