import { z } from "zod";

export const idTokenSchema = z.object({
  nickname: z.string(),
  name: z.string(),
  picture: z.string().url(),
  updated_at: z.string(),
  email: z.string().email(),
  email_verified: z.boolean(),
  iss: z.string().url(),
  aud: z.string(),
  iat: z.number(),
  exp: z.number(),
  sub: z.string(),
  sid: z.string(),
});

export const accessTokenSchema = z.object({
  iss: z.string().url(),
  sub: z.string(),
  aud: z.array(z.string().url()),
  iat: z.number(),
  exp: z.number(),
  azp: z.string(),
  scope: z.string(),
});

export const userSchema = z.object({
  created_at: z.string(),
  email: z.string().email().optional(),
  email_verified: z.boolean().optional(),
  identities: z.array(
    z.object({
      user_id: z.string(),
      provider: z.string(),
      connection: z.enum(["siwe", "discord", "Production", "staging-db"]),
      isSocial: z.boolean(),
    }),
  ),
  name: z.string(),
  nickname: z.string(),
  picture: z.string().url(),
  updated_at: z.string(),
  user_id: z.string(),
  user_metadata: z.object({}).optional(),
  app_metadata: z.object({}).optional(),
  username: z.string().optional(),
  last_ip: z.string().optional(),
  last_login: z.string().optional(),
  logins_count: z.number().optional(),
});

export const updateUserSchema = z.object({
  username: z.string().optional(),
  email: z.string().optional(),
  app_metadata: z.object({}).optional(),
});

export type Auth0User = z.infer<typeof userSchema>;
export type Auth0UserUpdate = z.infer<typeof updateUserSchema>;
export type Auth0AccessToken = z.infer<typeof accessTokenSchema>;
