import { z } from "zod";
import { collection, dashboard, query, visualization } from "..";
import { chartTypeSchema, legacyChartTypeSchema } from "../visualization/visualization";
import { visualizationTypeSchema } from "../visualization/v3/schema";

export const workItemTypeSchema = z.union([
  z.literal("collection"),
  z.literal("query"),
  z.literal("dashboard"),
  z.literal("visualization"),
  z.literal("table"),
]);

const workItemForkTypeSchema = z.enum(["query", "dashboard"]);
export const visTypeSchema = z.union([chartTypeSchema, legacyChartTypeSchema, visualizationTypeSchema]);
export const visibilitySchema = z.union([z.literal("public"), z.literal("private"), z.literal("link")]);
export const workItemVersionSchema = z.enum(["1", "2", "3"]);

export const schema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  slug: z.string().optional(),
  parentId: z.string().uuid().nullable(),
  createdById: z.string().uuid().optional(),
  updatedById: z.string().uuid().optional(),
  createdAt: z.coerce.date().default(new Date()),
  updatedAt: z.coerce.date().default(new Date()),
  //TODO: make this allow all new vis types
  // visType: visTypeSchema.optional().nullable(),
  visType: z.any(),
  typename: workItemTypeSchema,
  path: z.string().optional().nullable(),
  lastOpenedAt: z.coerce.date().optional().nullable(),
  profileId: z.string().uuid(),
  domainType: z.literal("WorkItem").default("WorkItem"),
  visibility: visibilitySchema.nullable(),
  version: workItemVersionSchema.optional().nullable(),
});

export const schemas = z.object({ items: z.array(schema) });

export const newSchema = schema
  .pick({
    name: true,
    typename: true,
    parentId: true,
    version: true,
  })
  .merge(
    z.object({
      visibility: visibilitySchema.default("public").optional(),
    }),
  );

export const updateSchema = schema.pick({
  id: true,
  typename: true,
  name: true,
});

export const moveSchema = schema.pick({
  id: true,
  typename: true,
  parentId: true,
});

export const forkSchema = schema
  .pick({
    id: true,
    parentId: true,
  })
  .merge(
    z.object({
      typename: workItemForkTypeSchema,
      visibility: visibilitySchema.default("public").optional(),
    }),
  );

export const deleteSchema = schema.pick({
  id: true,
  typename: true,
});

export const copyToProfileSchema = schema
  .pick({
    id: true,
    parentId: true,
    profileId: true,
  })
  .merge(
    z.object({
      typename: workItemForkTypeSchema,
      visibility: visibilitySchema.default("public").optional(),
    }),
  );

export type WorkItem = z.infer<typeof schema>;
export type WorkItemNew = z.infer<typeof newSchema>;
export type WorkItemUpdate = z.infer<typeof updateSchema>;
export type WorkItemMove = z.infer<typeof moveSchema>;
export type WorkItemDelete = z.infer<typeof deleteSchema>;
export type WorkItemFork = z.infer<typeof forkSchema>;
export type WorkItemCopyToProfile = z.infer<typeof copyToProfileSchema>;
export type WorkItemType = z.infer<typeof workItemTypeSchema>;
export type WorkItemSearchType = "all" | "query" | "dashboard" | "visualization";
export type WorkItemForkType = z.infer<typeof workItemForkTypeSchema>;
export type WorkItemVisibility = z.infer<typeof visibilitySchema>;
export type WorkItemVersion = z.infer<typeof workItemVersionSchema>;

export const fromCollection = (collection: collection.Collection): WorkItem => {
  return {
    id: collection.id,
    createdAt: collection.createdAt,
    updatedAt: collection.updatedAt,
    createdById: collection.createdById,
    updatedById: collection.updatedById,
    name: collection.name,
    parentId: collection.parentCollectionId,
    typename: "collection",
    slug: collection.slug,
    domainType: "WorkItem",
    profileId: collection.profileId,
    visibility: null,
    version: null,
  };
};

export const fromQuery = (query: query.Query): WorkItem => {
  return {
    id: query.id,
    createdAt: query.createdAt,
    updatedAt: query.updatedAt,
    createdById: query.createdById,
    updatedById: query.updatedById,
    name: query.name,
    parentId: query.queryCollectionId,
    typename: "query",
    slug: query.slug,
    domainType: "WorkItem",
    profileId: query.profileId,
    visibility: query.visibility,
    version: null,
  };
};

export const fromDashboard = (dashboard: dashboard.Dashboard): WorkItem => {
  return {
    id: dashboard.id,
    createdAt: dashboard.createdAt,
    updatedAt: dashboard.updatedAt,
    createdById: dashboard.createdById,
    updatedById: dashboard.updatedById,
    name: dashboard.title,
    parentId: dashboard.collectionId,
    typename: "dashboard",
    slug: dashboard.latestSlug,
    domainType: "WorkItem",
    profileId: dashboard.profileId,
    visibility: dashboard.visibility,
    version: dashboard.version,
  };
};

export const fromVisualization = (vis: visualization.Visualization): WorkItem => {
  return {
    id: vis.id,
    createdAt: vis.createdAt,
    updatedAt: vis.updatedAt,
    createdById: vis.createdById,
    updatedById: vis.updatedById,
    name: vis.title,
    parentId: vis.queryId ?? null,
    typename: "visualization",
    slug: vis.id,
    domainType: "WorkItem",
    profileId: vis.profileId,
    visibility: null,
    version: vis.version as WorkItemVersion,
  };
};

export const createNewItemFromTypeName = (
  typename: WorkItemType,
  input: string,
  parentId?: string | null,
): WorkItemNew => {
  return {
    typename,
    name: input,
    parentId: parentId ?? null,
  };
};
