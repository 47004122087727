import { z } from "zod";
import { Identity } from "../utils/identity";
import { Decimal } from "../utils/zod";
import { ZodSchema, decimalSchema } from "../utils/zod";
import { DealDelegationNew, dealDelegationCreateSchema } from "./deal-delegation";
import {
  TokenBasedGrantNew,
  UsdBasedGrantNew,
  dealTokenGrantCreateSchema,
  dealUsdGrantCreateSchema,
} from "./deal-grant";

export const partnerDealStatusSchema = z.enum(["RED", "YELLOW", "GREEN", "CREATED"]);
export type PartnerDealStatus = z.infer<typeof partnerDealStatusSchema>;

export type PartnerDeal = {
  id: string;
  partnerId: string;
  note: string | null;
  delegationId: string | null;
  grantId: string | null;
  totalEstValueUsd: Decimal;
  totalEstValueUsdSignDate: Decimal;
  status: PartnerDealStatus;
  createdById: string;
  createdAt: Date;
  updatedById: string | null;
  updatedAt: Date;
  startDate?: Date;
  endDate?: Date;
};

export type PartnerDealNew = Identity<
  Omit<
    PartnerDeal,
    | "id"
    | "createdAt"
    | "updatedAt"
    | "updatedById"
    | "grantId"
    | "delegationId"
    | "totalEstValueUsd"
    | "totalEstValueUsdSignDate"
    | "status"
    | "active"
    | "note"
  > & {
    delegation?: Omit<DealDelegationNew, "createdById" | "partnerDealId">;
    grant?:
      | (Omit<UsdBasedGrantNew, "createdById" | "partnerDealId"> & { grantType: "USD" })
      | (Omit<TokenBasedGrantNew, "createdById" | "partnerDealId"> & { grantType: "TOKEN" });
    status?: PartnerDealStatus;
    active?: boolean;
    note?: string;
    totalEstValueUsdSignDate?: Decimal;
  }
>;
export type PartnerDealUpdate = Identity<
  Partial<Omit<PartnerDeal, "createdAt" | "createdById" | "partnerId" | "updatedAt" | "id">> & {
    id: string;
  }
>;

export const partnerDealSchema = z.object({
  id: z.string().uuid(),
  partnerId: z.string().uuid(),
  delegationId: z.string().uuid().nullable().default(null),
  grantId: z.string().uuid().nullable().default(null),
  totalEstValueUsd: decimalSchema,
  totalEstValueUsdSignDate: decimalSchema,
  status: partnerDealStatusSchema,
  createdById: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedById: z.string().uuid().nullable().default(null),
  updatedAt: z.coerce.date().default(new Date()),
  startDate: z.coerce.date().optional(),
  endDate: z.coerce.date().optional(),
  active: z.boolean().default(true).optional(),
  note: z.string().nullable().default(null),
}) satisfies ZodSchema<PartnerDeal>;

export const partnerDealCreateSchema = partnerDealSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    updatedById: true,
    totalEstValueUsd: true,
    totalEstValueUsdSignDate: true,
    delegationId: true,
    grantId: true,
    note: true,
    createdById: true,
    startDate: true,
    endDate: true,
    active: true,
  })
  .extend({
    totalEstValueUsdSignDate: decimalSchema.optional(),
    delegation: dealDelegationCreateSchema.omit({ createdById: true, partnerDealId: true }).optional(),
    grant: z
      .discriminatedUnion("grantType", [
        dealUsdGrantCreateSchema.omit({ createdById: true, partnerDealId: true }),
        dealTokenGrantCreateSchema.omit({ createdById: true, partnerDealId: true }),
      ])
      .optional(),
    status: partnerDealStatusSchema.default("CREATED").optional(),
    // Whether to skip scheduling the deal-update workflow
    skipSchedule: z.boolean().default(false).optional(),
  }) satisfies ZodSchema<Omit<PartnerDealNew, "createdById">>;

export const partnerDealUpdateSchema = partnerDealSchema
  .partial()
  .extend({
    id: z.string().uuid(),
  })
  .omit({
    createdAt: true,
    createdById: true,
    delegationId: true,
    grantId: true,
  }) satisfies ZodSchema<PartnerDealUpdate>;
