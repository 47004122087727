import { z } from "zod";
import { planCodeSchema } from "../plan";
import { TRIAL_LENGTH_DAYS } from "../subscription";

export const statusSchema = z.enum(["active", "expired", "upgraded", "canceled"]);

export const schema = z.object({
  id: z.string().uuid(),
  profileId: z.string().uuid(),
  status: statusSchema,
  planCode: planCodeSchema,
  lengthInDays: z.number().int().default(TRIAL_LENGTH_DAYS),
  reminderEmailCount: z.number().int().default(0),
  startAt: z.coerce.date(),
  endAt: z.coerce.date(),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const newSchema = schema
  .pick({
    planCode: true,
  })
  .merge(
    z.object({
      lengthInDaysOverride: z.number().int().optional(),
    }),
  );

export const updateSchema = schema
  .pick({
    status: true,
    reminderEmailCount: true,
  })
  .partial();

export const filterSchema = z.object({
  profileId: z.string().uuid().optional(),
  status: statusSchema.optional(),
});

export type Trial = z.infer<typeof schema>;
export type TrialNew = z.infer<typeof newSchema>;
export type TrialUpdate = z.infer<typeof updateSchema>;
export type TrialStatus = z.infer<typeof statusSchema>;
export type TrialFilter = z.infer<typeof filterSchema>;
