import { z } from "zod";
import { schema as userSchema } from "../user/user";
import * as member from "../member/";

export const statusSchema = z.enum(["pending", "declined", "accepted", "deleted"]);

const baseSchema = z.object({
  id: z.string().uuid(),
  teamId: z.string().uuid(),
  status: statusSchema.default("pending"),
  role: member.memberRoleSchemaInvitable,
  invitedUserId: z.string().uuid().nullable(),
  invitedEmail: z.string().nullable(),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  invitedUser: userSchema.nullable().optional(),
  invitedUserIdOrEmail: z.string().nullable().optional(),
  team: z
    .object({
      name: z.string(),
    })
    .nullable()
    .optional(),
  domainType: z.literal("TeamInvitation").default("TeamInvitation"),
});

export const schema = baseSchema.refine(
  (data) => {
    const userIdNotNull = data.invitedUserId !== null;
    const emailNotNull = data.invitedEmail !== null;

    // Only one of them can be non-null
    return (userIdNotNull && !emailNotNull) || (!userIdNotNull && emailNotNull);
  },
  {
    message: "Only one of invitedUserId or invitedEmail can be non-null",
    path: ["invitedUserId", "invitedEmail"],
  },
);

export const updateSchema = baseSchema
  .pick({
    status: true,
    role: true,
    id: true,
  })
  .partial();

export const createSchema = baseSchema.pick({
  invitedUserIdOrEmail: true,
  teamId: true,
  role: true,
});

export const createFormSchema = baseSchema.pick({
  invitedUserIdOrEmail: true,
  role: true,
});

export function getInviteUrl(baseUrl: string, invite: TeamInvitation) {
  return `${baseUrl}/api/v1/invitations/${invite.id}/accept`;
}

export type TeamInvitation = z.infer<typeof baseSchema>;
export type TeamInvitationUpdate = z.infer<typeof updateSchema>;
export type TeamInvitationCreate = z.infer<typeof createSchema>;
export type TeamInvitationCreateForm = z.infer<typeof createFormSchema>;
export type TeamInvitationStatus = z.infer<typeof statusSchema>;
