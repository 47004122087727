import { z } from "zod";
import { workItem } from "..";

export const rankingsSchema = z.object({
  id: z.string(),
  rankingGreatest: z.number().nullable().optional(),
  rankingLive: z.number().nullable().optional(),
  rankingTop: z.number().nullable().optional(),
  rankingTrending: z.number().nullable().optional(),
});

export const queryDocSchema = z.object({
  id: z.string(),
  query: z.object({
    id: z.string(),
    statement: z.string().transform((val) => val.slice(0, 10000)),
    profileId: z.string(),
    createdAt: z.coerce.number(), // coerce into a number so we can sort by it
    updatedAt: z.coerce.number(),
    visibility: z.string(),
    name: z.string(),
  }),
  tags: z.array(z.string()),
  forkCount: z.number().default(0),
  qualityScore: z.number().default(0),
  ranking: rankingsSchema.optional(),
});

export const dashboardDocSchema = z.object({
  id: z.string(),
  dashboard: z.object({
    id: z.string(),
    title: z.string(),
    description: z.string(),
    profileId: z.string(),
    createdAt: z.coerce.number(), // coerce into a number so we can sort by it
    updatedAt: z.coerce.number(),
    visibility: z.string(),
  }),
  tags: z.array(z.string()),
  ranking: rankingsSchema.optional(),
});

export type QueryDoc = z.infer<typeof queryDocSchema>;
export type DashboardDoc = z.infer<typeof dashboardDocSchema>;
export type Rankings = z.infer<typeof rankingsSchema>;

export interface WorkItemDoc {
  workItem: workItem.WorkItem;
}

export type SortKey = `${"qualityScore"}:${"asc" | "desc"}`;

export interface DashboardSearchFilters {
  tags?: string[];
  publicOnly: boolean;
  ids?: string[];
  profileId?: string;
}

export interface SearchService {
  // Queries
  searchQueries(
    query: string,
    opts: {
      offset: number;
      limit: number;
      sort?: string[];
      filters?: { tags?: string[]; publicOnly?: boolean; profileId?: string };
    },
  ): Promise<{ hits: QueryDoc[]; total: number }>;
  indexQueries(queries: QueryDoc[]): Promise<void>;
  updateQuery(query: QueryDoc): Promise<void>;
  deleteQuery(id: string): Promise<void>;

  // Dashboards
  searchDashboards(
    query: string,
    opts: {
      offset: number;
      limit: number;
      sort?: string[];
      filters?: DashboardSearchFilters;
    },
  ): Promise<{ hits: DashboardDoc[]; total: number }>;
  indexDashboards(dashboards: DashboardDoc[]): Promise<void>;
  updateDashboard(dashboard: DashboardDoc): Promise<void>;
  deleteDashboard(id: string): Promise<void>;
  updateTrendingDashboards(rankings: Rankings[]): Promise<void>;
  updateTrendingQueries(rankings: Rankings[]): Promise<void>;
}
