import { z } from "zod";
import { schema as dashboardSchema } from "../dashboard";
import { queryParamSchema, schema as querySchema } from "../query";
import { schema as visualizationSchema } from "../visualization";

export const dashboardStorageSchema = dashboardSchema.pick({
  title: true,
  description: true,
  draft: true,
  visibility: true,
});

export const queryStorageSchema = z.object({
  id: querySchema.shape.id,
  name: querySchema.shape.name,
  parameters: z.array(queryParamSchema).default([]),
  ttlMinutes: querySchema.shape.ttlMinutes,
  visibility: querySchema.shape.visibility,
  meta: querySchema.shape.meta.optional().default({}),
  hasJsonEndpointAccess: querySchema.shape.hasJsonEndpointAccess,
});

export const queryStorageUpdateSchema = queryStorageSchema
  .pick({
    name: true,
    parameters: true,
    ttlMinutes: true,
    visibility: true,
    meta: true,
    hasJsonEndpointAccess: true,
  })
  .partial();

export const queryYdocSchema = z.object({
  codemirror: z.string().optional().default(""),
});

export const visualizationStorageSchema = visualizationSchema.pick({
  id: true,
  chart: true,
  version: true,
  title: true,
  legend: true,
  queryId: true,
  chartType: true,
});

export const dashboardStorageApiSchema = z.object({
  data: z.object({
    dashboardData: z.object({
      data: dashboardStorageSchema,
    }),
  }),
});

export const queryStorageApiSchema = z.object({
  data: z.object({
    query: z.object({
      data: queryStorageSchema,
    }),
    visualizations: z.object({
      data: z.object({
        ids: z.array(z.string()),
      }),
    }),
  }),
});

export const visualizationStorageApiSchema = z.object({
  data: z.object({
    visualization: z.object({
      data: visualizationStorageSchema,
    }),
  }),
});

export const tokenSchema = z.object({
  token: z.string(),
});

export type RealtimeDashboard = z.infer<typeof dashboardStorageSchema>;
export type RealtimeQuery = z.infer<typeof queryStorageSchema>;
export type RealtimeQueryUpdate = z.infer<typeof queryStorageUpdateSchema>;
export type RealtimeVisualization = z.infer<typeof visualizationStorageSchema>;
export type LiveblocksEntityType = "dashboard" | "query" | "visualization";
export type LiveblocksRoomType = "storage" | "ydoc";
export type LiveblocksToken = z.infer<typeof tokenSchema>;
