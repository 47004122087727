import { z } from "zod";
import { addOn } from "..";

export type DataExplorerAccessLevel = "internal" | "early-access" | "public";
export type DataExplorerNodeType = "database" | "schema" | "table" | "column";

export type DataExplorerNode = {
  id: string;
  name: string;
  comment: string;
  accessLevel: DataExplorerAccessLevel;
  isDeprecating: boolean;
  deprecationAt: Date;
  addedAt: Date;
  type: DataExplorerNodeType;
  children: DataExplorerNode[];
  dataType?: string;
};

export function buildNodeId(database: string, schema?: string, table?: string, column?: string) {
  let id = database;
  if (schema) id = `${id}.${schema}`;
  if (table) id = `${id}.${table}`;
  if (column) id = `${id}.${column}`;

  return id.toLowerCase();
}

export const functionArgSchema = z.object({
  name: z.string(),
  type: z.string(),
});

export const functionSchema = z.object({
  name: z.string(),
  args: z.array(functionArgSchema),
  returnType: z.string(),
  comment: z.string().nullable(),
});

export const liveQueryAddOnSchema = addOn.schema.pick({
  name: true,
  companyName: true,
  slug: true,
  logoImageUrl: true,
});

export const liveQueryProviderTypeSchema = z.union([z.literal("api"), z.literal("node"), z.literal("util")]);

export const liveQuerySchema = z.object({
  databaseName: z.string(),
  schemaName: z.string(),
  isInstalled: z.boolean(),
  iconUrl: z.string(),
  providerType: liveQueryProviderTypeSchema,
  addOns: z.array(liveQueryAddOnSchema),
  functions: z.array(functionSchema),
});

export type Function = z.infer<typeof functionSchema>;
export type FunctionArg = z.infer<typeof functionArgSchema>;
export type LiveQueryProviderType = z.infer<typeof liveQueryProviderTypeSchema>;
export type LiveQueryAddOn = z.infer<typeof liveQueryAddOnSchema>;
export type LiveQuerySchema = z.infer<typeof liveQuerySchema>;

export type FunctionSchemaFlat = {
  database: string;
  schema: string;
  name: string;
  isPrimary: boolean;
  args: FunctionArg[];
  returnType: string;
  comment: string;
  providerType: LiveQueryProviderType;
};

export const tablesRedisKey = "tables:all";
export const dataExplorerRedisKey = "snowflake:databases";
export const functionsRedisKey = "snowflake:functions:all";
export const functionsNestedRedisKey = "snowflake:functions:nested";
