import { z } from "zod";

export const paymentCustomerSchema = z.object({
  paymentId: z.string(), // ID associated with the payment system
  userId: z.string().uuid(),
  profileId: z.string().uuid(),
  email: z.string().email().nullable(),
  username: z.string(),
  deleted: z.boolean().default(false),
});

export const createPaymentCustomerSchema = paymentCustomerSchema
  .pick({
    userId: true,
    profileId: true,
    email: true,
    username: true,
  })
  .extend({
    paymentId: z.string().optional(),
  });

export const paymentMethodSchema = z.object({
  card: z
    .object({
      brand: z.string(),
      last4: z.string(),
      expMonth: z.number(),
      expYear: z.number(),
    })
    .optional(),
});
export const createPaymentMethodSchema = z.object({
  paymentId: z.string(),
  paymentSessionId: z.string(),
});

export const generateCheckoutSessionPayload = z.object({
  customer: paymentCustomerSchema,
  successUrl: z.string().url(),
  cancelUrl: z.string().url(),
});

export type PaymentCustomer = z.infer<typeof paymentCustomerSchema>;
export type CreatePaymentCustomer = z.infer<typeof createPaymentCustomerSchema>;
export type PaymentMethod = z.infer<typeof paymentMethodSchema>;
export type CreatePaymentMethod = z.infer<typeof createPaymentMethodSchema>;
export type GenerateCheckoutSessionPayload = z.infer<typeof generateCheckoutSessionPayload>;
