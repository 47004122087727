import { z } from "zod";
import { schema as secretContainerSchema } from "../secret-container/secret-container";
import { schema as secretSchema } from "../secret/secret";

export const addOnTypes = z.enum(["node", "api", "quicknode"]);

export const schema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  code: z.string(), // code to refer to the secret name under which key/value pairs live
  secretKeys: z.array(z.string()), // keys to use in form fields for secrets
  nodeSecretLabel: z.string().optional(),
  companyName: z.string(),
  slug: z.string(),
  overviewCopy: z.string().nullable(),
  descriptionCopy: z.string().nullable(),
  gettingStartedCopy: z.string().nullable(),
  usageCopy: z.string().nullable(),
  logoImageUrl: z.string().nullable(),
  marketingImageUrl: z.string().nullable(),
  documentationUrl: z.string().nullable(),
  websiteUrl: z.string().nullable(),
  examplesUrl: z.string().nullable(),
  supportEmail: z.string().nullable(),
  sortOrder: z.number().default(100),
  type: addOnTypes.default("api"),
  nodeProvider: z.string().nullable(),
  flag: z.string().optional(),
});

export const addOnUserContextSchema = z.object({
  isInstalled: z.boolean(),
  secretContainer: secretContainerSchema.nullable(),
  secretsKeyedByKeyName: z.record(secretSchema),
});

export const addOnWithUserContextSchema = schema.extend({
  userContext: addOnUserContextSchema,
});

export type AddOn = z.infer<typeof schema>;
export type AddOnType = z.infer<typeof addOnTypes>;
export type AddOnUserContext = z.infer<typeof addOnUserContextSchema>;
export type AddOnWithUserContext = z.infer<typeof addOnWithUserContextSchema>;
