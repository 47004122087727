import { z } from "zod";

export const schema = z.object({
  id: z.string().uuid(),
  key: z
    .string()
    .min(1)
    .regex(/^[A-Za-z0-9_-]+$/, "Key can only contain letters, numbers, underscores, and hyphens"),
  value: z.string().min(1),
  secretContainerId: z.string().uuid(),
  createdAt: z.date().default(new Date()),
  updatedAt: z.date().default(new Date()),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  domainType: z.literal("Secret").default("Secret"),
});

export const newSchema = schema.pick({
  key: true,
  value: true,
  secretContainerId: true,
});

export const updateSchema = schema.pick({
  key: true,
  value: true,
});

export type Secret = z.infer<typeof schema>;
export type SecretNew = z.infer<typeof newSchema>;
export type SecretUpdate = z.infer<typeof updateSchema>;
export type SecretsKeyedByKeyName = Record<string, Secret>;
