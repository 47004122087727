import { z } from "zod";
import { Usage } from "../subscription";

export const planCodes: PlanCode[] = [
  "PRO_1",
  "PRO_1_ANNUAL",
  "PRO_1_TRIAL",
  "BASIC_1",
  "BASIC_1_ANNUAL",
  "BASIC_1_TRIAL",
  "BASIC_1_FREE",
  "FREE_1",
  "builder", // deprecated
];

export const planCodeSchema = z.enum([
  "PRO_1",
  "PRO_1_ANNUAL",
  "PRO_1_TRIAL",
  "BASIC_1",
  "BASIC_1_ANNUAL",
  "BASIC_1_TRIAL",
  "BASIC_1_FREE",
  "FREE_1",
  "builder", // deprecated
]);

export const graduatedChargePropertySchema = z.object({
  amount: z.coerce.number(),
  ranges: z.array(
    z.object({
      fromValue: z.number(),
      toValue: z.number().nullable(),
      perUnitAmount: z.coerce.number(),
      flatAmount: z.coerce.number(),
    }),
  ),
});

export const graduatedChargesSchema = z.object({
  code: z.string(),
  chargeModel: z.literal("graduated"),
  properties: graduatedChargePropertySchema,
});

export const chargeSchema = z.discriminatedUnion("chargeModel", [graduatedChargesSchema]);

export const planSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  code: planCodeSchema,
  description: z.string().optional(),
  charges: z.array(chargeSchema),
  amountCents: z.number(),
  interval: z.enum(["monthly", "yearly", "none"]).catch("none"),
});

export type PlanCode = z.TypeOf<typeof planCodeSchema>;
export type Plan = z.TypeOf<typeof planSchema>;
export type Charge = z.TypeOf<typeof chargeSchema>;
export type GraduatedCharge = z.TypeOf<typeof graduatedChargesSchema>;

// Utils
export function getPaidPlanCodes(): PlanCode[] {
  return planCodes.filter((code) => !code.includes("TRIAL") && !code.includes("FREE"));
}

export function getValidChangePlansForPlan(plan: PlanCode): PlanCode[] {
  if (plan === "FREE_1") {
    return ["BASIC_1", "BASIC_1_TRIAL", "BASIC_1_ANNUAL", "PRO_1", "PRO_1_TRIAL", "PRO_1_ANNUAL"];
  }
  if (plan === "BASIC_1_TRIAL") {
    return ["FREE_1", "BASIC_1", "BASIC_1_ANNUAL", "PRO_1", "PRO_1_TRIAL", "PRO_1_ANNUAL"];
  }
  if (plan === "BASIC_1") {
    return ["FREE_1", "BASIC_1_ANNUAL", "PRO_1", "PRO_1_TRIAL", "PRO_1_ANNUAL"];
  }
  if (plan === "BASIC_1_ANNUAL") {
    return ["FREE_1", "BASIC_1", "PRO_1", "PRO_1_TRIAL", "PRO_1_ANNUAL"];
  }
  if (plan === "PRO_1_TRIAL") {
    return ["FREE_1", "BASIC_1", "BASIC_1_ANNUAL", "PRO_1", "PRO_1_ANNUAL"];
  }
  if (plan === "PRO_1") {
    return ["FREE_1", "BASIC_1", "BASIC_1_ANNUAL", "PRO_1_ANNUAL"];
  }
  if (plan === "PRO_1_ANNUAL") {
    return ["FREE_1", "BASIC_1", "BASIC_1_ANNUAL", "PRO_1"];
  }
  if (plan === "BASIC_1_FREE") {
    return ["FREE_1", "PRO_1", "PRO_1_TRIAL", "PRO_1_ANNUAL"];
  }
  return [];
}

export function getTrialPlanCodes(): PlanCode[] {
  return planCodes.filter((code) => code.includes("TRIAL"));
}

export function isAnnual(code: PlanCode): boolean {
  return code.includes("ANNUAL");
}

export function isTrial(code: PlanCode): boolean {
  return code.includes("TRIAL");
}

export function isFree(code: PlanCode): boolean {
  return code === "FREE_1";
}

export function isBasic(code: PlanCode): boolean {
  return code === "BASIC_1" || code === "BASIC_1_ANNUAL" || code === "BASIC_1_FREE";
}

export function isBasicFree(code: PlanCode): boolean {
  return code === "BASIC_1_FREE";
}

export function isPro(code: PlanCode): boolean {
  return code === "PRO_1" || code === "PRO_1_ANNUAL";
}

export function isProOrTrial(code: PlanCode): boolean {
  return isPro(code) || isProTrial(code);
}

export function isBasicOrTrial(code: PlanCode): boolean {
  return isBasic(code) || isBasicTrial(code);
}

export function isBasicTrial(code: PlanCode): boolean {
  return code === "BASIC_1_TRIAL";
}

export function isProTrial(code: PlanCode): boolean {
  return code === "PRO_1_TRIAL";
}

export function isValidPlanCode(code: PlanCode): boolean {
  return planCodes.includes(code);
}

export function isValidPaidPlanCode(code: PlanCode): boolean {
  return !isTrial(code) && !isFree(code);
}

export function getPaidPlanCodeFromTrial(code: PlanCode): PlanCode | undefined {
  if (isBasicTrial(code)) {
    return "BASIC_1";
  }
  if (isProTrial(code)) {
    return "PRO_1";
  }
  return undefined;
}

export function getTrialCodeFromPlan(plan: PlanCode): PlanCode | undefined {
  if (isBasic(plan)) {
    return "BASIC_1_TRIAL";
  }
  if (isPro(plan)) {
    return "PRO_1_TRIAL";
  }
  return undefined;
}

export function getMonthlyPrice(plan: Plan): number {
  if (isAnnual(plan.code)) {
    return plan.amountCents / 12 / 100;
  }
  return plan.amountCents / 100;
}

export function getAllowedQuerySeconds(plan: Plan): number {
  const querySecondCharges = plan.charges.find((c) => c.code === "query_seconds");
  if (!querySecondCharges) return 0;
  return querySecondCharges.properties.ranges[0]?.toValue ?? 0;
}

export function getUsageCostForPlan(usage: Usage, plan: Plan): number {
  const charge = plan.charges[0];
  if (!charge) return 0;
  if (charge.chargeModel === "graduated") {
    return getGraduatedChargeCost(usage, charge);
  }
  return 0;
}

export function getUsageAmount(usage: Usage): number {
  return usage.charges.reduce((acc, c) => acc + c.units, 0);
}

export function getPlanNameFromCode(code: PlanCode): string {
  switch (code) {
    case "FREE_1":
      return "Free";
    case "BASIC_1":
    case "BASIC_1_ANNUAL":
      return "Builder";
    case "PRO_1":
    case "PRO_1_ANNUAL":
      return "Pro";
    case "BASIC_1_FREE":
      return "Builder Free";
    case "BASIC_1_TRIAL":
      return "Builder Trial";
    case "PRO_1_TRIAL":
      return "Pro Trial";
    default:
      return "Unknown";
  }
}

function getGraduatedChargeCost(usage: Usage, charge: GraduatedCharge): number {
  const { ranges } = charge.properties;
  const { charges } = usage;
  const used = charges.reduce((acc, c) => acc + c.units, 0);
  const range = ranges.find((r) => {
    if (r.toValue) {
      return used >= r.fromValue && used < r.toValue;
    }
    return used >= r.fromValue;
  });
  if (!range) {
    return 0;
  }
  const { perUnitAmount, flatAmount, fromValue } = range;
  return perUnitAmount * (used - fromValue) + flatAmount;
}

export const defaultTTl = 720;
// this determines if a user is using a TTL lower than the plan allows
export const isTtlLessThanPlan = (allowedTTls: number[] = [defaultTTl], ttl: number = 0): boolean => {
  return allowedTTls.every((number) => ttl > 0 && ttl < number);
};
