import { z } from "zod";

const taxDocumentationStatusSchema = z.enum(["INVALID", "VALID", "PENDING", "NOT_STARTED"]);

export type TaxDocumentationStatus = z.TypeOf<typeof taxDocumentationStatusSchema>;

export const taxDocumentationSchema = z.object({
  status: taxDocumentationStatusSchema,
});

export type TaxDocumentation = z.TypeOf<typeof taxDocumentationSchema>;
